.footer{
    position: fixed;
    height: 60px;
    width: 100%;
    bottom: 0;
    background: white;
    z-index: 3;
    /* box-shadow: 0px -5px 32px -3px #80808063; */
    border-radius: 45px 45px 0px 0px;
}
.footer div {
    display: inline-block;
    width: 22%;
    text-align: center;
    padding: 7px 0px;
    border-radius: 50px;
    margin: 10px 6px;
    transition-duration: 0.4s;
}
.footer div:hover {
    background: white;
    transition-duration: 0.4s;
}
.footer #to-home {
    /* background: white; */
}
.footer to-collection {

}
.footer to-cart {

}
.footer to-account {

}