@import url('https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@400;500;600;700;800&display=swap');
/* html { scroll-behavior: smooth; } */
body{
    margin: 0px;
    padding: 0px;
    background: #eeecef;
    background-color: #ffffff;
    background-image: linear-gradient( 315deg, #ffffff 0%, #d7e1ec47 74% );
    font-family: 'Baloo Tammudu 2', cursive;
    line-height: 30px;
    color: gray;
    font-weight: bold;
    height: 100%;
}
.App{
    text-align: center;
}
.main-div{
    margin-top: 60px;
}