.card-small{
    /* height: 246px; */
    height: auto;
    width: 158px;
    display: inline-table;
    border-radius: 30px;
    background: white;
    margin: 30px 10px;
    padding: 10px;
    transition-duration: 0.3s;
    line-height: 20px;
    cursor: pointer;
    position: relative;
}
.card-small:hover{
    transition-duration: 0.3s;
    /* height: 256px;
    width: 168px; */
    box-shadow: 0px 3px 4px 2px #d4d3d499;
    /* margin: 20px 10px; */
}
.card-small img{
    width: 100% !important;
    border-radius: 30px;
    transition-duration: 0.3s;
}
.img-div{
    padding: 0px;
}

/* .price-small{
     display: flex;
    justify-content: space-between;
    font-weight: 600; 
} */
.card-small:hover .price-small{
    transition-duration: 0.3s;
    /* font-weight: 620; */
}
.card-small:hover .details-small{
    transition-duration: 0.3s;
    /* font-size: 25px; */
}
.overlay{
    width: 158px;
    position: absolute;
    height: 246px;
    padding: 10px;
    left: 0;
    top: 0;
}
.card-small.open-detail{
    width: 95%;
    height: 100%;
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    border-radius: 0px;
    margin: 0px;
}
.card-small.open-detail .card-small-scroll{
    overflow: scroll;
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 88px);
}
body.detail-page{
    overflow: hidden;
}
.card-small.open-detail .details-small{
    padding: 0px 40px;
    text-align: left;
    line-height: 30px;
}
.card-small.open-detail .details-small .product-type{
    font-weight: 400;
}
.card-small.open-detail .details-small .product-name, .card-small.open-detail .details-small .product-price{
    font-size: 25px;
    color: #000000e3;
    font-weight: 400;
}
.card-small.open-detail .img-div{
    padding: 0px 40px;
    padding-top: 100px;
}
.add-to-cart-btn{
    background: black;
    color: white;
    font-size: 22px;
    padding: 14px 22px;
    border: none;
    outline: none;
    border-radius: 30px;
    margin: 10px;
    width: 80%;
    font-weight: 600;
    max-width: 280px;
    transition-duration: 0.3s;
}
.add-to-cart-btn:hover {
    box-shadow: 0px 0px 22px 0px grey;
    transition-duration: 0.3s;
}
.hover-image{
    display: none;
}
.product-type{
    font-weight: 500;
    color: #8080808c;
}
.product-name{
    color: #808080a1;
}
span.product-original-price {
    text-decoration: line-through wavy black;
    text-decoration-style: double;
}