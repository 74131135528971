.product-detail{
    padding-top: 30px;
    // background:white;
    .carousel-div{
        margin: 0px 30px;
        .carousel.carousel-slider .control-arrow:hover {
            background: none !important;
        }
        .slider li.slide img{
            border-radius: 30px;
        }
    }
    .details-small{
        padding: 10px 0px;
        font-size: 18px;
        text-align: left;
        .product-type{
            font-weight: 500;
            color: #8080808c;
            font-weight: 400;
        }
        .product-name{
            font-size: 25px;
            color: #808080a1;
            font-weight: 400;
        }
        .product-price{
            font-size: 25px;
            color: #000000e3;
            font-weight: 400;
        }
        span.product-original-price {
            text-decoration: line-through wavy gray;
            text-decoration-style: double;
            color: gray;
        }
    }
    .product-detail-div{
        padding: 10px 40px;
    }
    .size-chart, .color-chart {
        justify-content: space-evenly;
        background: #efefef;
        display: flex;
        line-height: 30px;
        border-radius: 50px;
        padding: 3px;
        width: 50%;
        div {
            background: #000;
            height: 18px;
            padding: 8px;
            color: #efefef;
            margin: 2px;
            line-height: 26px;
            inline-size: -webkit-fill-available;
            text-align: center;
            border-radius: 50px;
            width: 8%;
            font-weight: bold;
        }
    }
    .detail-div{
        margin: 25px 0px 70px 0px;
        .details {
            padding: 5px 0px;
            text-align: left;
            .product-details-heading{
                color: black;
                font-size: 20px;
                font-weight: 600;
                line-height: 10px;
            }
            .product-details{
                font-weight: 400;
                font-size: 16px;
            }
        }
    }
    .add-to-cart-div{
        text-align: center;
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 8px;
    }
}