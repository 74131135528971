.view-image{
    position: relative;
    top: 0;
    left: 0;
    background: white;
    padding: 170px 10px;
    z-index: 60;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}
.view-image span{
    position: absolute;
    top: 56px;
    right: 10px;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }