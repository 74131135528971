@import url(https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@400;500;600;700;800&display=swap);
.footer{position:fixed;height:60px;width:100%;bottom:0;background:white;z-index:3;border-radius:45px 45px 0px 0px}.footer div{display:inline-block;width:22%;text-align:center;padding:7px 0px;border-radius:50px;margin:10px 6px;transition-duration:0.4s}.footer div:hover{background:white;transition-duration:0.4s}

.header-div{background:white;z-index:57;width:100%;position:fixed;top:0px;left:0px;padding:15px 0px}

.go-back{position:fixed;left:10px;top:18px;width:20px}.go-back img{width:100%}

html,body,div,span,applet,object,iframe,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,dl,dt,dd,ol,ul,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,label,legend,p,blockquote,table,caption,tbody,tfoot,thead,tr,th,td{margin:0;padding:0;border:0;outline:0;font-weight:inherit;font-style:inherit;font-size:100%;font-family:inherit;vertical-align:baseline;}body{line-height:1;color:black;background:white;}:focus{outline:0;}table{border-collapse:collapse;border-spacing:0;}caption,th,td{text-align:left;font-weight:normal;}fieldset,img{border:0;}address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal;}ol,ul{list-style:none;}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;}blockquote:before,blockquote:after,q:before,q:after{content:"";}blockquote,q{quotes:"" "";}abbr,acronym{border:0;}
a{color:black}
/* html { scroll-behavior: smooth; } */
body{
    margin: 0px;
    padding: 0px;
    background: #eeecef;
    background-color: #ffffff;
    background-image: linear-gradient( 315deg, #ffffff 0%, #d7e1ec47 74% );
    font-family: 'Baloo Tammudu 2', cursive;
    line-height: 30px;
    color: gray;
    font-weight: bold;
    height: 100%;
}
.App{
    text-align: center;
}
.main-div{
    margin-top: 60px;
}
.card-small{height:auto;width:158px;display:inline-table;border-radius:30px;background:white;margin:30px 10px;padding:10px;transition-duration:0.3s;line-height:20px;cursor:pointer;position:relative}.card-small:hover{transition-duration:0.3s;box-shadow:0px 3px 4px 2px #d4d3d499}.card-small img{width:100% !important;border-radius:30px;transition-duration:0.3s}.img-div{padding:0px}.card-small:hover .price-small{transition-duration:0.3s}.card-small:hover .details-small{transition-duration:0.3s}.overlay{width:158px;position:absolute;height:246px;padding:10px;left:0;top:0}.card-small.open-detail{width:95%;height:100%;position:fixed;z-index:4;top:0;left:0;border-radius:0px;margin:0px}.card-small.open-detail .card-small-scroll{overflow:scroll;position:fixed;overflow-x:hidden;overflow-y:scroll;height:calc(100% - 88px)}body.detail-page{overflow:hidden}.card-small.open-detail .details-small{padding:0px 40px;text-align:left;line-height:30px}.card-small.open-detail .details-small .product-type{font-weight:400}.card-small.open-detail .details-small .product-name,.card-small.open-detail .details-small .product-price{font-size:25px;color:#000000e3;font-weight:400}.card-small.open-detail .img-div{padding:0px 40px;padding-top:100px}.add-to-cart-btn{background:black;color:white;font-size:22px;padding:14px 22px;border:none;outline:none;border-radius:30px;margin:10px;width:80%;font-weight:600;max-width:280px;transition-duration:0.3s}.add-to-cart-btn:hover{box-shadow:0px 0px 22px 0px grey;transition-duration:0.3s}.hover-image{display:none}.product-type{font-weight:500;color:#8080808c}.product-name{color:#808080a1}span.product-original-price{-webkit-text-decoration:line-through wavy black;text-decoration:line-through wavy black;-webkit-text-decoration-style:double;text-decoration-style:double}

.view-image{position:relative;top:0;left:0;background:white;padding:170px 10px;z-index:60;animation:fadeIn 1s;-webkit-animation:fadeIn 1s;-moz-animation:fadeIn 1s;-o-animation:fadeIn 1s;-ms-animation:fadeIn 1s}.view-image span{position:absolute;top:56px;right:10px;animation:fadeIn 1s;-webkit-animation:fadeIn 1s;-moz-animation:fadeIn 1s;-o-animation:fadeIn 1s;-ms-animation:fadeIn 1s}@-webkit-keyframes fadeIn{0%{opacity:0}100%{opacity:1}}@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}


.product-detail{padding-top:30px}.product-detail .carousel-div{margin:0px 30px}.product-detail .carousel-div .carousel.carousel-slider .control-arrow:hover{background:none !important}.product-detail .carousel-div .slider li.slide img{border-radius:30px}.product-detail .details-small{padding:10px 0px;font-size:18px;text-align:left}.product-detail .details-small .product-type{font-weight:500;color:#8080808c;font-weight:400}.product-detail .details-small .product-name{font-size:25px;color:#808080a1;font-weight:400}.product-detail .details-small .product-price{font-size:25px;color:#000000e3;font-weight:400}.product-detail .details-small span.product-original-price{-webkit-text-decoration:line-through wavy gray;text-decoration:line-through wavy gray;-webkit-text-decoration-style:double;text-decoration-style:double;color:gray}.product-detail .product-detail-div{padding:10px 40px}.product-detail .size-chart,.product-detail .color-chart{-webkit-justify-content:space-evenly;justify-content:space-evenly;background:#efefef;display:-webkit-flex;display:flex;line-height:30px;border-radius:50px;padding:3px;width:50%}.product-detail .size-chart div,.product-detail .color-chart div{background:#000;height:18px;padding:8px;color:#efefef;margin:2px;line-height:26px;inline-size:-webkit-fill-available;text-align:center;border-radius:50px;width:8%;font-weight:bold}.product-detail .detail-div{margin:25px 0px 70px 0px}.product-detail .detail-div .details{padding:5px 0px;text-align:left}.product-detail .detail-div .details .product-details-heading{color:black;font-size:20px;font-weight:600;line-height:10px}.product-detail .detail-div .details .product-details{font-weight:400;font-size:16px}.product-detail .add-to-cart-div{text-align:center;position:fixed;width:100%;left:0;bottom:8px}

